const jobData = [
  {
    title: "Web Developer & Digital Designer",
    description:
      "Web developers create and maintain websites. They are also responsible for the site’s technical aspects, such as its performance and capacity, which are measures of a website’s speed and how much traffic the site can handle. In addition, web developers may create content for the site. Digital designers develop, create, and test website or interface layout, functions, and navigation for usability. They are responsible for the look and functionality of the website or interface.",
    tasks: [
      "Meet with clients or management to discuss the needs, design, and functionality of a website or interface.",
      "Create and test applications, interfaces, and navigation menus for a website.",
      "Work with graphics and other designers to determine the website’s layout.",
      "Design and develop graphics and maintain websites. Digital designers develop, create, and test a website's layout or interface, functionality, and navigation for usability.",
    ],
    skills:
      "HTML, CSS, Javascript Framework, Rest Apis, SQL Server, UI/UX, PHP, Laravel, Node, Javascript.",
    matkul: "Advance Web Programming.",
  },
  {
    title: "Computer System Analysts",
    description:
      "Computer systems analysts, sometimes called systems architects, study an organization’s current computer systems and procedures and design improvements to them. In doing so, these analysts help the organization operate more efficiently.",
    tasks: [
      "Analyze costs and benefits of IT systems and upgrades to help managers decide which, if any, to install.",
      "Devise ways to add functionality to existing computer system.",
      "Design new systems by configuring hardware and software.",
      "Oversee the installation and configuration of new systems and customize them for the organization.",
      "Test systems to ensure that they work as expected.",
      "Write instruction manuals and train the systems’ end users.",
    ],
    skills:
      "Business Analysis, Data Analysis, Project Management, System Analysis, Analytical Skills, Data Visualization, Agile Project Management",
    matkul: "Sistem Informasi Geografis Energi Ketenagalistrikan.",
  },
  {
    title: "Software Developer, Quality Assurance Analysts, and Testers",
    description:
      "Software developers create the computer applications that allow users to do specific tasks and the underlying systems that run the devices or control networks. Software quality assurance analysts and testers design and execute software tests to identify problems and learn how the software works.",
    tasks: [
      "Analyze users’ needs and then design and develop software to meet those needs.",
      "Design each piece of an application or system and plan how the pieces will work together.",
      "Create a variety of models and diagrams showing programmers the software code needed for an application.",
      "Identify project risks and recommend steps to minimize those risks.",
      "Ensure that a program continues to function normally through software maintenance and testing.",
      "Testing software.",
    ],
    skills:
      "Software Testing, Test Automation, Quality Assurance, Software Development, Project Management, Product Management, Python, Java Spring, Frameworks.",
    matkul: "Software Project Management, Software Quality and Metric.",
  },
  {
    title: "Information Security Analysts",
    description:
      "Information security analysts plan and carry out security measures to protect an organization’s computer networks and systems..",
    tasks: [
      "Check for vulnerabilities in computer and network systems.",
      "Research the latest information technology (IT) security trends.",
      "Prepare reports that document general metrics, attempted attacks, and security breaches.",
      "Develop security standards and best practices for their organization.",
      "Help computer users when they need to install or learn about new security products and procedures.",
    ],
    skills:
      "Information Security, Cyber Security, Net Security, Application Security, Security Engineer, Infrastructure Security, Osi Layer, Security Analyst",
    matkul: "-",
  },
  {
    title: "Computer Programmers",
    description:
      "Computer programmers write, modify, and test code and scripts that allow computer software and applications to function properly. They turn the designs created by software developers and engineers into instructions that a computer can follow. In addition, programmers run tests to ensure that newly created applications and software produce the expected results. If the products do not work correctly, programmers check the code or scripts for mistakes and modify them.",
    tasks: [
      "Write programs in a variety of computer languages, such as C++ and Java.",
      "Update and expand existing programs.",
      "Test programs for errors and fix the faulty lines of computer code.",
      "Create, modify, and test code or scripts in software that simplifies development.",
    ],
    skills:
      "Object Oriented Programming, HTML, CSS, Rest Apis, Software Development, Java, Javascript, Python, Mobile Developer, UI/UX Design, Fullstack Developer",
    matkul:
      "Advance Mobile Programming.",
  },
  {
    title: "Computer Support Specialists",
    description:
      "Computer support specialists assist computer users and organizations. These specialists either maintain computer networks or provide technical help directly to computer users.",
    tasks: [
      "Test and evaluate existing network systems.",
      "Analyze customers’ computer problem to diagnose it and determine the cause.",
      "Document customers’ descriptions of their computer problems.",
      "Inform team members and managers of major problems or of customers’ recurring concerns",
    ],
    skills:
      "Hardware, Software, Technical Support, Application Support, Computer System, Computer Hardware, Computer Net, LAN, WAN, Net Problem, Installation Configuration, Education Computer",
    matkul: "-",
  },
  {
    title: "Database Administrators and Architects",
    description:
      "Database administrators and architects create or organize systems to store and secure a variety of data, such as financial information and customer shipping records. They also make sure that the data are available to authorized users..",
    tasks: [
      "Identify user needs to create and administer databases.",
      "Design and build new databases.",
      "Make and test modifications to database structure when needed.",
      "Maintain databases and update permissions.",
    ],
    skills:
      "Database Administrator, SQL Server, Database Design, Backup Recovery, Backup Restore, Database System, Database Management.",
    matkul: "Database Optimization, Sistem Informasi Geografis Energi Ketenagalistrikan, and Advance Database.",
  },
  {
    title: "Computer And Information Reserch Scientists",
    description:
      "Computer and information research scientists design innovative uses for new and existing technology. They study and solve complex problems in computing for business, science, medicine, and other fields..",
    tasks: [
      "Explore problems in computing and develop theories and models to address those problems.",
      "Collaborate with scientists and engineers to solve complex computing problems.",
      "Develop new computing languages, software systems, and other tools to improve how people work with computers.",
      "Design and conduct experiments to test the operation of software systems, frequently using techniques from data science and machine learning.",
      "Analyze the results of their experiments.",
    ],
    skills:
      "Data Science, Data Scientist, Machine Learning, Data Analysis, Data management, Data Visualization",
    matkul:
      "Introducing to Data Science, Dataset Development, Data Mining dan Warehousing, Pengantar Data Science, Embedded System, Soft Computing, Programmable Logic Controller, Big Data Energi Ketenagalistrikan, Dataset Develompent, Mikrocontrollernt, and Data Mining and Warehouse.",
  },
  {
    title: "Computer Network Architects",
    description:
      "Computer network architects design and implement data communication networks, including local area networks (LANs), wide area networks (WANs), and intranets. These networks range from small connections between two offices to virtual capabilities such as a cloud infrastructure that serves multiple customers..",
    tasks: [
      "Create plans and layouts for data communication networks.",
      "Present designs to management, customers, and staff.",
      "Upgrade hardware, such as routers or adaptors, and software, such as network drivers, as needed to support computer networks.",
      "Research and recommend new technologies for network performance.",
    ],
    skills:
      "Net Engineer, Net Security, Red Hat, Net Infrastructure, Solution Architect, Router Switch, Architecture Design, Cloud Technology, Azure, Devops, Cloud Technology, Deploy Functional, Net, LAN, WLAN",
    matkul: "-",
  },
  {
    title: "Network and Computer System Administrator",
    description:
      "Network and computer systems administrators install, configure, and maintain organizations’ local area networks (LANs), wide area networks (WANs), data communication networks, operating systems, and servers..",
    tasks: [
      "Maintain network and computer system security.",
      "Evaluate and optimize network and system performance.",
      "Train users on the organization’s network and systems.",
      "Diagnose and resolve problems when alerted by a user or an automated monitoring system.",
    ],
    skills:
      "Network Engineer, System Administrator, Operate System, Hardware, Software, Network Administrator, Computer Network, Router Switch, Server Network, System Security",
    matkul: "-",
  },
];

export default jobData;
