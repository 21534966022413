import Title from "../../components/Layout/Title";
import Navbar from "../../components/Navbar Section/NavbarSection";
import Footer from "../../components/Footer Section/FooterSection";
import "./Styles.css";
function Form() {
    return (
        <>
        <Title title="Form">
        <>
          <Navbar />
          <div className="form-center">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdpdETSL9I-GgHqs8dhZvdaoJq4Sv8bBN0CaelR-GeU191EoQ/viewform?embedded=true" width="640" height="1611" frameborder="0" marginheight="0" marginwidth="0" title="Form Evaluasi">Memuat…</iframe>
          </div>
          <Footer/>
        </>
        </Title>
        </>    
          );
}

export default Form;