import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import jobPersonality from "../../data/Jobs Personality/JobsPersonality"
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Styles.css";
import Error from "../Error/Error";
import Title from "../../components/Layout/Title";
import Navbar from "../../components/Navbar Section/NavbarSection";
import Footer from "../../components/Footer Section/FooterSection";

function DashboardUser() {
  const [testUsers, setTestUsers] = useState([]);
  const navigate = useNavigate();

  const fetchTestUsers = () => {
    fetch("https://api-careerpaths.my.id/v1/test-user/" + localStorage.getItem("userId"), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result) {
          setTestUsers(data.result);
        }
      })
      .catch((error) => {
        console.error("Error fetching test users:", error);
      });
  };

  useEffect(() => {
    fetchTestUsers();
  }, []);

  const handleClick = (testResult) => {
    const personalityType = testResult.toUpperCase();

    navigate(`/personality/${personalityType}`);
  };



  return (
    <>
      {localStorage.getItem("token") ? (
        <>
          <Title title="User">
            <>
            <Navbar />
            <br />
              <div className="dashboard">
                <p className="intro-title">Dashboard</p>
                <p className="intro-subtitle">
                  Berikut adalah hasil tes yang sudah
                  menyelesaikan tes MBTI
                </p>
                <button className="button-right" >
                <Link to="/test-page" style={{color:"white"}}>Go To Test</Link>
              </button>

                {testUsers.length === 0 ? (
                  <p className="intro-subtitle" style={{ fontWeight: "bold" }}>
                    Data tidak tersedia
                  </p>
                ) : (
                  <Table striped bordered className="table-admin">
                    <thead>
                      <tr>
                        <th>No.</th>                                     
                        <th>Test Result</th>
                        <th>Pekerjaan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testUsers.map((user, index) => (
                        <tr
                          className="baris"
                          key={`${user.id}_${index}`}
                          onClick={() => handleClick(user.test_result)}
                        >
                          <td>{index + 1}</td>                        
                          <td>{user.test_result}</td>
                          <td>{jobPersonality[user.test_result]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </>
          </Title>
        </>
      ) : (
        <>
        <Footer/>
          <>
            <Error />
          </>
        </>
      )}
    </>
  );
}

export default DashboardUser;
